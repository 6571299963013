<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2 space-y-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <form
        class="space-y-4"
        @submit.prevent="store(specialization)"
        @keydown="removeError($event.target.id)">
        <h3 class="my-4 text-2xl">Crear especialidad</h3>

              <a-input
                label="Código"
                required
                class="mb-4"
                id="code"
                :error="error('code')"
                v-model="specialization.code"/>

              <a-input
                label="Título"
                required
                class="mb-4"
                id="title"
                :error="error('title')"
                v-model="specialization.title"/>

              <div class="flex flex-wrap pl-2 -mx-2 mb-4">
                <div>
                  <div class="h-32 w-32 mr-2 mb-6">
                    <label for="color" class="mb-2 text-gray-700 block font-medium">Color</label>
                    <v-verte v-model="specialization.color" model="hex" style="justify-content: flex-start">
                      <div class="w-32 h-32 border p-4 rounded-lg flex justify-center items-center">
                        <a-icon name="paint-roller" />
                      </div>
                    </v-verte>
                    <p v-if="hasError('color')" style="color: #FF4600">{{ error('color') }}</p>
                  </div>
                  <p class="pt-2">
                    {{ specialization.color }}
                  </p>
                </div>
                <div>
                  <div class="mb-6 h-32 w-32">
                    <label for="text_color" class="mb-2 text-gray-700 block font-medium">Color de texto</label>
                    <v-verte v-model="specialization.text_color" model="hex" style="justify-content: flex-start">
                      <div class="w-32 h-32 border p-4 rounded-lg flex justify-center items-center">
                        <a-icon name="font" class="text-3xl" />
                      </div>
                    </v-verte>
                    <p v-if="hasError('text_color')" style="color: #FF4600">{{ error('text_color') }}</p>
                  </div>
                  <p class="pt-2">
                    {{ specialization.text_color }}
                  </p>
                </div>
              </div>

              <label for="enabled" class="mb-2 text-gray-700 block font-medium">Activa</label>
              <a-switch id="enabled" v-model="specialization.enabled"/>

          <div class="flex space-x-2">
            <a-button mode="primary" type="submit" :loading="loading">
              {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
            </a-button>

            <a-button outlined :to="{ name: 'specializations' }">
              Cancelar
            </a-button>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        specialization: {
            code: '',
            title: '',
            // credits: 0,
            color: '#000000',
            text_color: '#000000',
            enabled: false
        },
        isUpdating: false
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            specializations: state => state.specializations.pagination.data,
            currentSpecializationOnState: state => state.specializations.specialization,
            loading: state => state.specializations.loading
        })
    },
    methods: {
        ...mapActions({
            fetchSpecialization: 'specializations/fetchSpecialization',
            store: 'specializations/store',
            update: 'specializations/update',
            removeError: 'removeError',
        }),
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        })
    },
    watch: {
        currentSpecializationOnState(val) {
            this.specialization = {...val}
        }
    },
    created() {
        const id = this.$route.query.update
        this.setNavbarTitle(['Listado de especialidades', 'Crear especialidad'])
        if (id) {
            this.isUpdating = true

            const found = this.specializations.find(specialization => specialization.id === parseInt(id))

            if (found) {
                this.specialization = {...found}
                this.setNavbarTitle(['Listado de especialidades', found.title, 'Editar especialidad'])
            } else {
                this.fetchSpecialization(id)
            }
        }
    }
}
</script>
